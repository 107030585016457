import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  class: "block",
  style: {"margin-top":"25px"}
}
const _hoisted_2 = { class: "content" }
const _hoisted_3 = {
  class: "block",
  style: {"margin-bottom":"45px"}
}
const _hoisted_4 = { class: "title is-size-5" }
const _hoisted_5 = { class: "block" }
const _hoisted_6 = { class: "subtitle is-size-6 mb-0" }
const _hoisted_7 = { class: "block" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  class: "button is-info is-multiline is-activ",
  style: {"pointer-events":"none"}
}
const _hoisted_10 = { key: 1 }
const _hoisted_11 = {
  class: "button is-info is-multiline is-ppp",
  style: {"pointer-events":"none"}
}
const _hoisted_12 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconsByImageCollection = _resolveComponent("IconsByImageCollection")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      ($setup.isFromSearch)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            class: "is-link-monizze",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.onBack && $setup.onBack(...args)))
          }, _toDisplayString(_ctx.$t('back')), 1))
        : (_openBlock(), _createElementBlock("a", {
            key: 1,
            class: "is-link-monizze",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.onClose && $setup.onClose(...args)))
          }, _toDisplayString(_ctx.$t('close')), 1))
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h3", _hoisted_4, _toDisplayString($setup.currentMerchant.data.name || $setup.currentMerchant.data.title), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("p", _hoisted_6, _toDisplayString($setup.currentMerchant.data.address), 1)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("p", null, _toDisplayString($setup.currentMerchant.data.categoryCommaSeparated), 1)
      ])
    ]),
    ($setup.currentMerchant.data.tagList.includes('ONLY_ACTIV'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("button", _hoisted_9, _toDisplayString(_ctx.$t('activ.banner')), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.$route.query.voucher_type === '5')
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('ppp.banner')), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_12, [
      _createVNode(_component_IconsByImageCollection, {
        icons: $setup.icons,
        isMultiple: true,
        class: "is-small is-borderless"
      }, null, 8, ["icons"])
    ])
  ]))
}

import {useRouter, useRoute, onBeforeRouteLeave} from "vue-router";
import {inject, onMounted, onBeforeMount, ref, Ref, watch} from "vue";
import {useMapsComposable} from "@/composables/MapsComposable";
import {usePlaces} from "@/composables/PlacesComposable";
import IconsByImageCollection from "@/components/elements/IconsByImageCollection.vue";
import {categoryStore} from "@/stores/CategoryStore";

const products: Array<string> = [
  "emv", "eco", "gift", "activ", "ppp"
];

export default {
  name: "MerchantDetailsPage",
  props: {
    merchantId: {
      type: Number,
      required: true,
    },
  },
  components: {
    IconsByImageCollection
  },
  emits: ["select"],
  setup(props: { merchantId: number }, {emit}: any) {
    const placeStore: any = inject("placeStore");
    const searchStore: any = inject("searchStore");
    const vouchersStore: any = inject("vouchersStore");
    const {selectMarker} = useMapsComposable();
    const {fetchMerchant, fetchPlaces} = usePlaces();
    const currentMerchant = ref();
    const voucherType = ref();
    const router = useRouter();
    const route = useRoute();
    const isFromSearch = !!route.query.from_search;
    const icons: Ref<Array<string>> = ref([]);
    let merchantcounter = 0;

    onMounted(async () => {
      console.log("mounted MERCHANT");
      const from_search = route.query.from_search;
      await render();
    });

    // onBeforeMount(async () => {
    //   console.log("Beforemounted MERCHANT")
    //   await render();
    // });

    watch(() => props.merchantId, async (n, o) => {
      console.log("watch MERCHANT")
      await render();
    });

    const render = async () => {
      console.log('MDP : Start rendering Merchant detail page. merchantcounter: ' + merchantcounter)

      const markers = await placeStore.getters.getItems;

      const refData = ref();

      const selectedVoucherType = vouchersStore.actions.findElement(route.query.voucher_type).ico;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      currentMerchant.value = await fetchMerchant(props.merchantId.toString(), selectedVoucherType);
      currentMerchant.value.data.categoryLabelList = [];
      if (currentMerchant.value.data.categoryList) {
              for (const cat of currentMerchant.value.data.categoryList){
        currentMerchant.value.data.categoryLabelList.push(categoryStore.actions.findElement(cat))
      }

      }
      currentMerchant.value.data.categoryCommaSeparated =
          currentMerchant.value.data.categoryLabelList.map((u: { label: string; }) => u.label)
          .filter(function (obj:any) {return obj })
          .join(', ') //todo niet alle categorieen komen er goed door... Wordt altijd herladen.

      //add , between postcode and city
      currentMerchant.value.data.address = currentMerchant.value.data.address.replace(/ (?=\d\d\d\d )/,', ')
      refData.value = currentMerchant.value.data ? currentMerchant.value.data.products : currentMerchant.value.products;
      icons.value = [];

      // Iterate products object from merchant details data in order to provide icons
      for (let product in refData.value) {
        if (refData.value[product]) {
          icons.value.push(product);
        }
      }
      merchantcounter++
      console.log(icons.value)

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      selectMarker(props.merchantId, vouchersStore.actions.findElement(parseInt(route.query.voucher_type))?.ico);

      emit("select", props.merchantId);
    };

    const onBack = (e: Event) => {
      if (!route.query.from_search) {
        router.go(-merchantcounter);

      } else {
        router.go(-1);
      }
    };

    const onClose = (e: Event) => {
      router.push({
        name: "Home",
        query: {
          ...route.query,
          search_type: undefined,
          search_criteria: undefined
        }
      });
    };

    onBeforeRouteLeave((to, from) => {
       const mark = placeStore.getters.getMarkerHighlight

      if (to.name === from.name) {
        router.go(-1);
      }
    });

    return {
      currentMerchant,
      placeStore,
      searchStore,
      isFromSearch,
      icons,
      onBack,
      onClose
    }
  },
};
